<template>
<div class="container-top">
  <div class="pc flex-justify">
    <div class="container">
      <div style="margin:120px 0;width:100%">
        <div class="margin-bottom-40 section-title main text-center">가격안내</div>
        <table>
          <thead>
          <tr>
            <template v-for="(item,idx) in priceInfo">
              <th v-if="item.type==='head'" :key="'head-'+idx" style="width:190px"
                  class="th-empty"></th>
              <th v-else :key="'head-'+idx" style="padding:16px 14px"
                  :class="[item.title!=='Prime' ? 'price-head' : 'price-prime']">
                <div class="margin-bottom-12 h7">{{ item.title }}</div>
                <div class="body5">{{ item.desc1 }}</div>
                <div class="body5 margin-bottom-12">{{ item.desc2 }}</div>
                <button class="price-btn"
                        :class="[item.title==='Prime' ? 'price-btn-bk' : 'button-primary']"
                        @click="routerPush(item.link)">
                  <div class="body2-medium">{{ item.btn }}</div>
                </button>
              </th>
            </template>
          </tr>
          </thead>
          <tbody>
          <tr>
            <td rowspan="5" style="vertical-align:middle"
                class="text-left body4 sub3">{{ priceInfo[0].plan }}</td>
            <td colspan="3" class="td-exp">
              <div class="col-12 flex-center">
                <div class="flex-align">
                  <img src="/static/img/home/u_clock-seven.svg" class="svg-primary">
                  <div class="body4-medium primary" style="margin-left:4px">14일 무료체험 제공</div>
                </div>
              </div>
            </td>
            <td rowspan="5" class="body2 sub2"
                style="vertical-align:middle;white-space: pre-line">{{ priceInfo[4].plan }}
            </td>
          </tr>
          <!-- 플랜 -->
          <tr v-for="unit in ['one','three','six','twelve']" :key="'tr-'+unit">
            <td v-for="idx in 3" :key="'price-info-'+idx">
              <div class="col-12">
                <div class="flex-between">
                  <div class="body4 sub">{{ priceInfo[idx].plan[unit].title }}</div>
                  <div class="sub4" style="text-decoration:line-through">{{ priceInfo[idx].org_price | currencyNum }}원</div>
                </div>
                <div class="text-right">
                  <template v-if="priceInfo[idx].plan[unit].discount_rate">
                    <span class="body2-bold primary" style="margin-right:4px">{{ priceInfo[idx].plan[unit].discount_rate }}%</span>
                    <span class="body2-bold main">{{ priceInfo[idx].org_price * (1 - (0.01*priceInfo[idx].plan[unit].discount_rate)) | currencyNum}}원</span>
                  </template>
                  <template v-else>
                    <span class="body2-bold main">{{ priceInfo[idx].org_price | currencyNum}}원</span>
                  </template>
                  <span class="body4 sub4">/1개월</span>
                </div>
              </div>
            </td>
          </tr>
          <tr v-for="key in ['signup', 'prodReg', 'prodCat', 'partReg', 'admin', 'ticket', 'device']" :key="'info-key-'+key">
            <td class="body4 sub3 text-left">{{ priceInfo[0][key] }}</td>
            <td class="body4-medium sub" v-for="i in 4" :key="`${key}-${i}`">{{ priceInfo[i][key] }}</td>
          </tr>
          </tbody>
        </table>
        <div class="body2 sub3 text-right" style="margin-top:16px">*사용 가능 플랫폼 팩과 플러그인은 지속적으로 추가될 예정입니다.</div>
      </div>
    </div>
  </div>
  <div class="tablet mobile container" style="margin-top:25px">
    <div class="col-12 text-center margin-bottom-12 main mo-section-title-bold">가격안내</div>
      <div v-for="(item,idx) in priceInfo" :key="'price-mo-'+idx"
        class="col-12">
        <div class="price-item-box" v-if="idx>0" :style="idx===4 ? { borderTop: '3px solid black' } : ''">
          <div class="text-center item-title" :class="[ item.title==='Prime' ? 'main' : 'primary']">
            <div class="margin-bottom-8 h6">{{ item.title }}</div>
            <div class="margin-bottom-16 body5">{{ item.desc1 }} {{ item.desc2 }}</div>
            <button class="button body3-medium" :class="[ item.title==='Prime' ? 'button-black' : 'button-primary']"
              style="width:200px;height:40px"
              @click="routerPush(item.link)">{{ item.btn }}</button>
          </div>
          <div class="item-title2">
            <template v-if="item.title==='Prime'">
              <div v-html="optPlan(item.plan)" class="col-12"></div>
            </template>
            <template v-else>
              <div v-for="val in Object.values(item.plan)" :key="'key-'+val.title">
                <div class="flex-between margin-bottom-12">
                  <div class="body4 sub">{{ val.title }}</div>
                  <div class="body4-bold">
                    <span v-if="val.discount_rate" class="primary" style="margin-right:4px">{{ val.discount_rate }}%</span>
                    <span class="main">{{ val.discount_rate ? item.org_price*((100-val.discount_rate)/100) : item.org_price | currencyNum }}</span>
                    <span class="body4">원</span>
                    <span class="body4 sub">/1개월</span></div>
                </div>
              </div>
            </template>
          </div>
          <div>
            <div class="flex-align margin-top-20">
              <img src="/static/img/home/u_check.svg" style="margin-right:9px;width:12px">
              <div class="body4 sub3" style="margin-right:6px">회원가입 수</div>
              <div class="body4-medium.sub">{{ item.signup }}</div>
            </div>
            <div class="flex-align margin-top-20">
              <img src="/static/img/home/u_check.svg" style="margin-right:9px;width:12px">
              <div class="body4 sub3" style="margin-right:6px">상품 등록 수</div>
              <div class="body4-medium.sub">{{ item.prodReg }}</div>
            </div>
            <div class="flex-align margin-top-20">
              <img src="/static/img/home/u_check.svg" style="margin-right:9px;width:12px">
              <div class="body4 sub3" style="margin-right:6px">상품 카테고리 수</div>
              <div class="body4-medium.sub">{{ item.prodCat }}</div>
            </div>
            <div class="flex-align margin-top-20">
              <img src="/static/img/home/u_check.svg" style="margin-right:9px;width:12px">
              <div class="body4 sub3" style="margin-right:6px">파트너 등록 수</div>
              <div class="body4-medium.sub">{{ item.partReg }}</div>
            </div>
            <div class="flex-align margin-top-20">
              <img src="/static/img/home/u_check.svg" style="margin-right:9px;width:12px">
              <div class="body4 sub3" style="margin-right:6px">관리자 계정 갯수</div>
              <div class="body4-medium.sub">{{ item.admin }}</div>
            </div>
            <div class="flex-align margin-top-20">
              <img src="/static/img/home/u_check.svg" style="margin-right:9px;width:12px">
              <div class="body4 sub3" style="margin-right:6px">블럭 템플릿 무제한 이용권</div>
              <div class="body4-medium.sub">{{ item.ticket }}</div>
            </div>
            <div class="flex-align margin-top-20">
              <img src="/static/img/home/u_check.svg" style="margin-right:9px;width:12px">
              <div class="body4 sub3" style="margin-right:6px">모바일/PC</div>
              <div class="body4-medium.sub">{{ item.device }}</div>
            </div>
          </div>
        </div>
      </div>
  </div>
</div>
</template>
<script>
export default {
  name: "PriceInfo",
  data() {
    return {
      priceInfo: [
          {
            type: 'head',
            plan: '플랜',
            signup: '회원가입 수',
            prodReg: '상품 등록 수',
            prodCat: '상품 카테고리 수',
            partReg: '파트너 등록 수',
            admin: '관리자 계정 갯수',
            ticket: '블럭 템플릿 무제한 이용권',
            device: '모바일/PC'
          },
          {
            expand: true,
            title: 'Basic',
            desc1: '최소 스펙으로',
            desc2: '나만의 MVP를 구현해 보고 싶을 때',
            btn: '14일 무료체험',
            link: '/service_create?step=1',
            org_price: 140000,
            plan: {
              one: {
                title: '1개월',
              },
              three: {
                title: '3개월',
                discount_rate: 10
              },
              six: {
                title: '6개월',
                discount_rate: 30
              },
              twelve: {
                title: '12개월',
                discount_rate: 50
              },
            },
            signup: '500명',
            prodReg: '100건',
            prodCat: '10개',
            partReg: '20건',
            admin: '1명',
            ticket: '베타버전 무료제공',
            device: '모바일'
          },
          {
            expand: true,
            title: 'Basic PC+',
            desc1: 'MVP 서비스를 모바일 뿐만 아니라',
            desc2: 'PC웹 서비스까지',
            btn: '14일 무료체험',
            link: '/service_create?step=1',
            org_price: 240000,
            plan: {
              one: {
                title: '1개월',
              },
              three: {
                title: '3개월',
                discount_rate: 10
              },
              six: {
                title: '6개월',
                discount_rate: 30
              },
              twelve: {
                title: '12개월',
                discount_rate: 50
              },
            },
            signup: '500명',
            prodReg: '100건',
            prodCat: '10개',
            partReg: '20건',
            admin: '1명',
            ticket: '베타버전 무료제공',
            device: '모바일/PC'
          },
          {
            expand: true,
            title: 'Start-up',
            desc1: '씨드투자 유치 전',
            desc2: '본격적인 회원/파트너 등록이 필요할 때',
            btn: '14일 무료체험',
            link: '/service_create?step=1',
            org_price: 280000,
            plan: {
              one: {
                title: '1개월',
              },
              three: {
                title: '3개월',
                discount_rate: 10
              },
              six: {
                title: '6개월',
                discount_rate: 30
              },
              twelve: {
                title: '12개월',
                discount_rate: 50
              },
            },
            signup: '10000명',
            prodReg: '3000건',
            prodCat: '20개',
            partReg: '100건',
            admin: '5명',
            ticket: '베타버전 무료제공',
            device: '모바일/PC'
          },
          {
            expand: true,
            title: 'Prime',
            desc1: '나에게 꼭 맞춘',
            desc2: '커스텀 플랫폼',
            btn: '제작 상담 신청',
            link: '/prime?form=true',
            plan: '상담 후 진행\n단계별 견적 안내',
            signup: '무제한',
            prodReg: '무제한',
            prodCat: '무제한',
            partReg: '무제한',
            admin: '무제한',
            ticket: '베타버전 무료제공',
            device: '모바일/PC'
          }
        ]
    }
  },
  methods: {
    optPlan(name) {
      let word = name.replace('\n','<br>')
      return `<div class="col-12 body3 sub2 text-center">${word}</div>`;
    },
  }
}
</script>
<style lang="stylus" scoped>
  @import '~assets/css/lp_main'
  table
    width 100%
  th
    border 1px solid $gray1
  td
    padding 16px
    text-align center
    border 1px solid $gray1

  .td-exp
    background-color $primary-light2
    padding 6px
    border-bottom 0

  .th-empty
    border-top none
    border-left none

  .price-btn-bk
    background-color black
    color white

  .price-btn
    width 210px
    border-radius 8px
    padding 8px 0

  .price-prime
    color black
    border-top 3px solid black

  .price-head
    color $primary
    border-top 3px solid $primary

  .button-primary
  .button-home-circle
    border 0
    background linear-gradient(322.3deg, #FF6600 22.51%, #F43B00 100%)
    color white

  .button-primary-light
    background-color white
    color $primary
    border 1px solid $primary

  .price-item-box
    border-top 3px solid $primary
    border-left 1px solid $gray1
    border-right 1px solid $gray1
    border-bottom 1px solid $gray1
    padding 16px 16px 22px
    margin-bottom 24px
    width 100%

  .item-title
    padding-bottom 16px
    border-bottom 1px solid $gray1

  .item-title2
    padding 12px 0
    border-bottom 1px solid $gray1

  .pc
    .container
      height 100%
      display flex
      align-items center
      flex-wrap wrap
      max-width 1152px !important

  @media (max-width: 1024px)
    .plan-sale
      padding 12px 0
      text-align center
      border-top 1px solid $gray2
      border-bottom 1px solid $gray2
      margin 12px 0

      img
        vertical-align sub

    .plan-item
      border 0
      padding 10px 0

    .option-st
      text-align left
      border 1px solid $gray1
      border-radius 8px
      margin-bottom 20px
      padding 16px 20px

    .option-st.selected
      padding 15px 19px

    .tag-primary
      color $primary
      background-color $primary-light2
      border-radius 4px
      font-size 13px
      padding 2px 8px
</style>
